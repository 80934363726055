.register {
  display: flex;
  width: 100%;

  .registerContainer {
    flex: 6;

    .bottom {
      padding: 10px;
      margin: 20px;
      display: flex;
    }

    .button {
      width: 150px;
      padding: 10px;
      border: none;
      background-color: #8280ea;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-top: 10px;

      &:disabled {
        background-color: rgba(124, 132, 204, 0.322);
        cursor: not-allowed;
      }
    }
  }
  .icon {
    cursor: pointer;
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}