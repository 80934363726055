/* @import url("https://fonts.googleapis.com/css2?family=Avenir:wght@400;500;800&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

input,
select,
textarea {
  border: 0;
  font-size: 16px;
}
input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: 0px;
}
::placeholder {
  color: #dddddd;
  opacity: 1; /* Firefox */
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}

html,
body,
#root,
.app {
  height: 100%;
  overflow: auto;
}

.bg-text-marine-blue {
  background-color: #323582;
}
a {
  text-decoration: none;
}
a:active {
  color: currentColor;
}

/* .login-shadow::before {
  content: url("../components/svg/LoginShadow.svg");
  position: absolute;
  top: -249px;
  left: -193px;
} */
.login {
  background-image: url("../assets/LoginRevolution_bg.png");
}

.login-gradient {
  background: linear-gradient(90deg, #131642 0%, #3138a8 40%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0.5;
  z-index: 0;
}

.MuiAccordion-root {
  background-color: #ebebeb !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiTableCell-head {
  font-size: 16px !important;
  font-weight: bold !important;
}

.recharts-wrapper {
  width: 100% !important;
}

.MuiSelect-select {
  border-radius: 0.375rem !important;
  padding: 0 !important;
}
.MuiInputBase-root {
  border-radius: 0.375rem !important;
  border: 1px solid #646464 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}
.MuiSelect-multiple {
  padding: 0 !important;
  height: auto !important;
}

/* scrollbar css */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  /* display: none; */
}
/* :hover::-webkit-scrollbar{
  display: block;
} */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
background-color: darkgrey;
}
