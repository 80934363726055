@import url("https://fonts.googleapis.com/css2?family=Avenir:wght@400;800&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --heading-1: Avenir;

  /* font sizes */
  --heading-1-size: 48px;
  --body-1-size: 16px;
  --body-2-size: 13px;
  --sub-heading-5-size: 20px;
  --heading-2-size: 40px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;

  /* Colors */
  --white: #fff;
  --brand-marine-blue: #323582;
  --ui-light-grey: #f2f2f2;
  --brand-sea-blue: #3138a8;
  --text-grey-25: #ddd;
  --text-body-blue: #2c3850;
  --ui-dark-grey: #646464;

  /* Gaps */
  --gap-22xl: 41px;
  --gap-xl: 20px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-21xl: 40px;
  --padding-139xl: 158px;
  --padding-xl: 20px;
  --padding-7xl: 26px;
  --padding-12xs: 1px;
  --padding-36xl: 55px;
  --padding-8xl: 27px;
  --padding-11xs: 2px;
  --padding-9xs: 4px;
  --padding-4xs: 9px;

  /* Border radiuses */
  --br-7xs: 6px;
}