@import "./../../style/const.scss";

.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: white;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 100px;
      height: 100px;
      object-fit: contain;
      margin-top: 20px;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;
    margin-top: 50px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #7451f8;
        }

        span {
          font-size: 18px;
          font-weight: 600;
          color: #888;
          margin-left: 10px;
        }

        &.active {
          background-color: #ece8ff;

          span {
            color: #7451f8;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

.sidebar-tailwind {
  background-color: #1e1e4c;
  padding: 56px 8px 15px 8px;
  min-height: 100vh;
  width: 256px;
  z-index: 999;
  position: relative;

  @media (max-width: 750px) {
    position: absolute;
    z-index: 999;
  }
  li {
    padding: 16px 24px;
    color: #ffffff;
    font-family: avenir, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
  }
  svg {
    min-width: 24px;
  }
}

.sidebar-compressed {
  background-color: #1e1e4c;
  padding: 56px 8px 15px 8px;
  min-height: 100vh;
  width: 64px;
  z-index: 999;
  position: relative;

  @media (max-width: 750px) {
    position: absolute;
    z-index: 999;
  }
  li {
    padding: 16px 12px;
    color: #ffffff;
    font-family: avenir, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
    svg {
      min-width: 24px;
    }
  }
}
